/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/**
 * Layout.tsx
 *
 * This component is responsible to hold all pages.
 * It should scaffold all the animation stuffs (Lenis & GSAP).
 *
 * @author Mystro Ken <mystroken@gmail.com>
 */
import React from "react"
import type { WindowLocation } from "@reach/router"

import Transition from "./transition"
import ScrollManager from "./scroll-manager"

import Main from "@/components/main"
import Header from "@/components/header"
import Footer from "@/components/footer"
import Cursor from "@/components/cursor"
import addMagneticEffect from "@/interactions/magnetic"
import addTextRollOverEffect from "@/interactions/text-roll-over"
import addTextRevealEffect from "@/interactions/text-reveal"
import useEvents from "@/hooks/use-events"
import useNavbarStore from "@/components/navbar/store"
import { useViewportObserver } from "@/hooks/use-viewport"
import useGSAP from "@/hooks/use-gsap"

type LayoutProps = {
  location: WindowLocation<unknown>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>
}

/**
 * Layout constructor.
 */
const Layout = ({ children, location }: LayoutProps) => {
  const events = useEvents()
  const [setNavIsOpen, setNavIsPulledUp] = useNavbarStore((state) => [
    state.setOpened,
    state.setPulledUp,
  ])
  const canRenderCursor =
    typeof window !== "undefined" &&
    !(
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    )
  useGSAP()
  // Attach the viewport change observer.
  useViewportObserver()

  React.useLayoutEffect(() => {
    let cleanupTextRevealEffect: () => void
    let cleanupMagneticEffect: () => void
    let cleanupRollOverEffect: () => void

    events.on("transition:enter:start", () => {
      cleanupTextRevealEffect = addTextRevealEffect()
      cleanupRollOverEffect = addTextRollOverEffect()
      setTimeout(() => {
        cleanupMagneticEffect = addMagneticEffect()
      }, 1000)
    })

    events.on("transition:leave:start", () => {
      cleanupTextRevealEffect && cleanupTextRevealEffect()
      cleanupMagneticEffect && cleanupMagneticEffect()
      cleanupRollOverEffect && cleanupRollOverEffect()
    })

    events.on("transition:enter:end", () => {
      setNavIsPulledUp(false)
    })
  }, [])

  /**
   * Each time
   * a location is changed.
   */
  React.useEffect(() => setNavIsOpen(false), [location])

  // return (
  //   <>
  //     <ScrollManager trigger={location} />
  //     <Transition to={location}>
  //       <>
  //         <Header />
  //         <main id="main">{children}</main>
  //         <Footer triggers={[location]} />
  //         {canRenderCursor && <Cursor />}
  //       </>
  //     </Transition>
  //   </>
  // )

  return (
    <Transition to={location}>
      <ScrollManager trigger={location} />
      {/* <Scrollbar> */}
      <Header />
      <Main>{children}</Main>
      <Footer triggers={[location]} />
      {canRenderCursor && <Cursor />}
      {/* </Scrollbar> */}
    </Transition>
  )
}

export default Layout
