import { useStaticQuery, graphql, GatsbyGraphQLType } from 'gatsby'

export type SiteMenuItem = {
  label: string
  path: string
}

export const useSiteMenu = () => {
  const {
    allWpMenu: { edges: menus },
  }: Queries.SiteMenuQuery = useStaticQuery(graphql`
    query SiteMenu {
      allWpMenu {
        edges {
          node {
            locations
            menuItems {
              nodes {
                label
                path
              }
            }
          }
        }
      }
    }
  `)

  const primaryLinks: SiteMenuItem[] = menus
    .filter(({ node: { locations } }) => locations.includes('PRIMARY'))
    .map(({ node: { menuItems } }) => menuItems.nodes)
    .flat()

  const socialNetworkLinks: SiteMenuItem[] = menus
    .filter(({ node: { locations } }) => locations.includes('NETWORKS'))
    .map(({ node: { menuItems } }) => menuItems.nodes)
    .flat()

  return { primaryLinks, socialNetworkLinks }
}
