import React from 'react'

import Navbar from '@/components/navbar'

const Header = () => (
  <>
    <a
      href="#main"
      aria-label="Skip to main content"
      title="Skip to main content"
      className="fixed top-0 left-0 w-full text-center -translate-y-full focus:translate-y-0"
    >
      Skip to main content
    </a>
    <Navbar />
  </>
)

export default Header
