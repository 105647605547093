import React, { useRef } from "react"
import cn from "clsx"

import { useStore } from "@/store/use-store"
import useNavbarStore from "../store"
import * as css from "./burger.module.css"

interface BurgerProps {
  className?: string
  onClick?: () => void
}

const Burger: React.FC<BurgerProps> = (props) => {
  const { className, onClick } = props
  const $rootRef = useRef<HTMLButtonElement>(null)
  const navIsOpen = useNavbarStore((state) => state.opened)
  const setCursorVisible = useStore((state) => state.setCursorVisible)

  return (
    <button
      {...props}
      ref={$rootRef}
      className={cn(css.button, className, navIsOpen && "is-open")}
      aria-label="Toggle navigation"
      aria-controls="navbarCollapse"
      type="button"
      onClick={onClick}
      onMouseEnter={() => setCursorVisible(true)}
      onMouseLeave={() => setCursorVisible(false)}
      data-magnetic-fixed
    >
      <span className={css.container}>
        <span className={css.line} />
        <span className={css.line} />
      </span>
    </button>
  )
}

export default Burger
