import { gsap } from "gsap"

// Cover
const cover = {
  start: "M 0 100 V 100 Q 50 100 100 100 V 100 z",
  middle: "M 0 100 V 50 Q 50 0 100 50 V 100 z",
  end: "M 0 100 V 0 Q 50 0 100 0 V 100 z",
}

// Reveal
const reveal = {
  start: "M 0 0 V 100 Q 50 100 100 100 V 0 z",
  middle: "M 0 0 V 50 Q 50 100 100 50 V 0 z",
  end: "M 0 0 V 0 Q 50 0 100 0 V 0 z",
}

export const leave = () => {
  const $path = document.querySelector<SVGPathElement>(".path")
  const navbar = document.querySelector<HTMLElement>("#navbar")
  if (navbar) {
    // navbar.classList.add("pull-up")
  }
  return (
    gsap
      .timeline({ paused: true })
      .set($path, { attr: { d: cover.start } })
      .to($path, {
        attr: { d: cover.middle },
        // duration: 0.8,
        duration: 0.6,
        ease: "power3.in",
      })
      .to($path, {
        attr: { d: cover.end },
        duration: 0.3,
        ease: "power2.out",
      })
      // Main content Parallax.
      .fromTo(
        "#main",
        {
          y: 0,
        },
        {
          duration: 0.6,
          ease: "power3.in",
          y: -200,
        },
        0.2
      )
  )
  // .from($logo, { y: 75, duration: 0.8 }, "-=.8")
}

export const enter = () => {
  const $path = document.querySelector<SVGPathElement>(".path")
  const navbar = document.querySelector<HTMLElement>("#navbar")
  // if (navbar) {
  //   navbar.classList.remove("pull-up")
  // }
  return (
    gsap
      .timeline({ paused: true })
      .set($path, { attr: { d: reveal.start } })
      // .set("#main", { y: 200 })
      .to($path, {
        attr: { d: reveal.middle },
        duration: 0.3,
        ease: "power2.in",
      })
      .to($path, {
        attr: { d: reveal.end },
        duration: 0.8,
        ease: "power4.out",
      })
      // Main content Parallax.
      .fromTo(
        "#main",
        {
          y: 200,
        },
        {
          duration: 0.8,
          ease: "power3.out",
          y: 0,
        },
        0.2 // "-=.8"
      )
  )
}
