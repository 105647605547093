import type Lenis from "@studio-freight/lenis"
import { shallow } from "zustand/shallow"
import { createWithEqualityFn } from "zustand/traditional"

export interface Store {
  lenis: Lenis | null
  setLenis: (lenis: Lenis | null) => void
  cursorIsVisible: boolean
  setCursorVisible: (isVisible: boolean) => void
}

export const useStore = createWithEqualityFn<Store>(
  (set, get) => ({
    // Lenis
    lenis: null,
    setLenis: (lenis: Lenis | null) => set({ lenis }),

    // Cursor
    cursorIsVisible: false,
    setCursorVisible: (isVisible: boolean) =>
      set((state) => ({ cursorIsVisible: isVisible })),
  }),
  shallow
)
