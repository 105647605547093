import React from "react"
import type { GatsbyBrowser } from "gatsby"
import useEvents from "./src/hooks/use-events"
import Layout from "./src/components/layout"

// Import global stylesheet.
import "./src/style.css"
import { gsap } from "gsap"

const events = useEvents()

/**
 * Wrap our content inside a same layout.
 */
export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}

// Disable
// the default restoration strategy.
if ("scrollRestoration" in window.history) {
  window.history.scrollRestoration = "manual"
}

export const onPreRouteUpdate: GatsbyBrowser["onPreRouteUpdate"] = ({
  location,
  prevLocation,
}) => {
  // Check if we should
  // emit a transition start.
  if (window.scrollNeedRestoration || !prevLocation) return
  if (prevLocation.pathname === location.pathname)
    return window.location.reload()

  // Emit a new transition start.
  events.emit("transition:leave:start", { location, prevLocation })
}

/**
 * On initial render
 * Listen for Back/Forward History event
 * in order to know if we should restore the scroll or not.
 */
export const onInitialClientRender: GatsbyBrowser["onInitialClientRender"] =
  () => {
    // When we forced the reload,
    // we need to reset the scroll position.
    window.scrollTo(0, 0)

    // We only need to restore
    // the scroll on pop state.
    window.addEventListener("popstate", () => {
      window.scrollNeedRestoration = true
    })

    gsap.delayedCall(1, () => events.emit("transition:enter:start"))
  }

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // Only restore on pop state.
  if (window.scrollNeedRestoration) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const savedPosition: number[] = getSavedScrollPosition(location) || [0, 0]
    window.scrollTo(savedPosition[0], savedPosition[1])
    window.scrollNeedRestoration = false
  }
  return false
}
