import React from "react"
import type { WindowLocation } from "@reach/router"
import useEvents from "@/hooks/use-events"
import transitions, { TransitionItemType } from "./transitions"

type TransitionProps = {
  to: WindowLocation<WindowLocationState>
  children: React.ReactNode
}

class Transition extends React.Component<TransitionProps> {
  #events = useEvents()
  #isPageChanging = false
  #transition: TransitionItemType | undefined = undefined

  componentDidMount(): void {
    /**
     * When the transition leave is complete.
     */
    this.#events.on("transition:leave:end", () => {
      this.#isPageChanging = false
      this.forceUpdate()
      window.scrollTo(0, 0)
      // Emit the transition end.
      setTimeout(() => {
        this.#events.emit("dom:content:ready")
        this.#events.emit("transition:enter:start")
      }, 200)
    })

    /**
     * When transition leave started.
     */
    this.#events.on(
      "transition:leave:start",
      async ({ prevLocation }: TransitionEvent) => {
        this.#isPageChanging = true

        // Get the transition to play.
        const transitionType = prevLocation?.state?.transitionType
        const currentTransition = transitionType ? transitionType : "slide"
        this.#transition = transitions[currentTransition]

        // Play the transition.
        if (this.#transition) {
          await this.#transition.leave().play(0)
          this.#events.emit("transition:leave:end")
        }
      }
    )

    /**
     * When transition enter start.
     */
    this.#events.on("transition:enter:start", async () => {
      this.#transition = this.#transition
        ? this.#transition
        : transitions["slide"]

      await this.#transition.enter().play(0)
    })
  }

  shouldComponentUpdate(): boolean {
    return !this.#isPageChanging
  }

  render(): JSX.Element {
    return <>{this.props.children}</>
  }
}
export default Transition
