/* eslint-disable @typescript-eslint/no-unsafe-argument */
import cn from "clsx"
import { Link } from "gatsby"
import React, { useCallback, useEffect, useRef } from "react"
import { shallow } from "zustand/shallow"

import { useScroll } from "@/hooks/use-scroll"
import { useSiteMenu } from "@/hooks/use-site-menu"
import NavbarToggler from "./burger"
import NavbarNavigation from "./navigation"
import useNavbarStore from "./store"

import * as css from "./navbar.module.css"

/**
 * Navbar Component
 *
 * The navbar holds the following components :
 * - The logo
 * - The toggler
 * - The navigation items
 *
 * @author Mystro Ken <mystroken@gmail.com>
 */
const Navbar = () => {
  // Get primary navigation links.
  const { primaryLinks } = useSiteMenu()

  // Connect to the store.
  const [
    opened,
    toggleOpened,
    centered,
    pulledUp,
    setPulledUp,
    backgroundBlended,
    setBackgroundBlended,
  ] = useNavbarStore(
    (state) => [
      state.opened,
      state.toggleOpened,
      state.centered,
      state.pulledUp,
      state.setPulledUp,
      state.backgroundBlended,
      state.setBackgroundBlended,
    ],
    shallow
  )

  // Handle click on toggler.
  const handleClickOnToggler = useCallback(() => toggleOpened(), [])

  // Pull up navbar on scroll down.
  const $navbarRef = useRef<HTMLElement>(null)
  const lastScrollValue = useRef<number>(0)

  const scrollDistanceToTop = useRef<number>(0)
  const scrollToTopStartPosition = useRef<number>(0)

  useScroll(({ scroll }: { scroll: number }) => {
    if ($navbarRef.current) {
      // if we are scrolling down,
      // pull-up the navbar.
      if (scroll > lastScrollValue.current + 5) {
        setPulledUp(true)

        // Reset the scroll to top measure.
        scrollDistanceToTop.current = 0
        scrollToTopStartPosition.current = 0
      } else {
        if (scrollToTopStartPosition.current === 0) {
          scrollToTopStartPosition.current = scroll
        }
        scrollDistanceToTop.current = Math.abs(
          scroll - scrollToTopStartPosition.current
        )
      }

      // To pull down the navbar if,
      // either we reached the top or we
      // scrolled up for a certain distance.
      if (scroll <= 10 || scrollDistanceToTop.current > 100) {
        setPulledUp(false)
      }

      lastScrollValue.current = scroll
    }
  })

  useEffect(() => {
    $navbarRef.current?.classList.add("-blend-mode")
    const isBackgroundColorTransition = (e: TransitionEvent) =>
      e.target === $navbarRef.current && e.propertyName === "background-color"

    /**
     * add blend mode when the background
     * color transition is ended. The goal is to
     * leave the time to the animation to be played.
     */
    const addBlendMode = (event: TransitionEvent) => {
      isBackgroundColorTransition(event) &&
        !$navbarRef.current?.classList.contains("nav-open") &&
        setBackgroundBlended(true)
    }

    /**
     * remove the blend mode as soon as the
     * background transition started from zero to one.
     */
    const removeBlendMode = (event: TransitionEvent) => {
      isBackgroundColorTransition(event) &&
        $navbarRef.current?.classList.contains("nav-open") &&
        setBackgroundBlended(false)
    }

    $navbarRef.current?.addEventListener("transitionend", addBlendMode)
    $navbarRef.current?.addEventListener("transitionstart", removeBlendMode)

    return () => {
      $navbarRef.current?.removeEventListener("transitionend", addBlendMode)
      $navbarRef.current?.removeEventListener(
        "transitionstart",
        removeBlendMode
      )
    }
  }, [$navbarRef])

  return (
    <header
      ref={$navbarRef}
      id="navbar"
      className={cn(
        css.navbar,
        opened && "nav-open",
        centered && "-center",
        backgroundBlended && "-blend-mode",
        pulledUp && "pull-up"
      )}
    >
      <div
        className={cn(
          "container flex flex-row items-center flex-wrap",
          "xl:grid xl:grid-cols-12 xl:gap-4"
        )}
      >
        <NavbarToggler
          onClick={handleClickOnToggler}
          className={cn(
            css.navbarToggler,
            "mr-6 xl:mr-0 xl:absolute xl:left-[2.78vw]"
          )}
        />

        <div className={cn(css.navbarBrand, "xl:col-start-2 xl:col-span-2")}>
          <Link
            to="/"
            aria-label="Homepage"
            title="Go to homepage"
            className={css.navbarBrandLink}
            data-magnetic-fixed
          >
            <span className={css.navbarLogo} data-rollover>
              mystroken
            </span>
          </Link>
        </div>

        <NavbarNavigation links={primaryLinks} className={css.navbarNav} />
      </div>
    </header>
  )
}

export default Navbar
