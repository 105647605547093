import { shallow } from "zustand/shallow"
import { createWithEqualityFn } from "zustand/traditional"

export interface INavbarStore {
  centered: boolean
  setOpened: (isOpen: boolean) => void
  toggleOpened: () => void

  opened: boolean
  setCentered: (isCentered: boolean) => void

  pulledUp: boolean
  setPulledUp: (isPulledUp: boolean) => void

  backgroundBlended: boolean
  setBackgroundBlended: (isBlended: boolean) => void
}

export default createWithEqualityFn<INavbarStore>(
  (set) => ({
    // Opened
    opened: false,
    setOpened: (isOpen: boolean) => set({ opened: isOpen }),
    toggleOpened: () => set((state) => ({ opened: !state.opened })),

    // Centered
    centered: false,
    setCentered: (isCentered: boolean) => set({ centered: isCentered }),

    // Pull up
    pulledUp: false,
    setPulledUp: (isPulledUp: boolean) => set({ pulledUp: isPulledUp }),

    // Background Blended
    backgroundBlended: true,
    setBackgroundBlended: (isBlended: boolean) =>
      set({ backgroundBlended: isBlended }),
  }),
  shallow
)
