import cn from "clsx"
import { gsap } from "gsap"
import React, { useCallback, useRef, useState } from "react"
import * as css from "./back-to-top.module.css"

import { useScroll } from "@/hooks/use-scroll"

/**
 * BackToTop component
 * allows to back to the top and show the scroll progress
 * @author Mystro Ken <mystroken@gmail.com>
 */
const BackToTop = () => {
  const circleRadius = 45
  const circleDashArray = Math.ceil(2 * Math.PI * circleRadius)
  const circleRef = useRef<SVGCircleElement>(null)
  const containerRef = useRef<HTMLAnchorElement>(null)
  const [disabled, setDisabled] = useState(true)

  /**
   * When Scrolling,
   * We want to show a progress bar (by using our svg circle).
   * And decide if the button must be disabled or not.
   */
  useScroll(({ progress }: { progress: number }) => {
    // Disable (display)
    // or not the button.
    setDisabled(progress < 0.0618)

    // The progress bar.
    if (!circleRef.current) return
    const circleDashOffset = circleDashArray - progress * circleDashArray
    circleRef.current.setAttribute("stroke-dashoffset", `${circleDashOffset}`)
  })

  const scaleUpButton = useCallback(() => {
    containerRef.current &&
      gsap.to(containerRef.current, {
        scale: 1,
        ease: "expo.out",
        duration: 0.4,
        force3D: true,
        overwrite: true,
      })
  }, [])

  const scaleDownButton = useCallback(() => {
    containerRef.current &&
      gsap.to(containerRef.current, {
        scale: 0.8,
        ease: "expo.out",
        duration: 0.4,
        force3D: true,
        overwrite: true,
      })
  }, [])

  return (
    <a
      ref={containerRef}
      data-silently-scroll-to
      href="#___gatsby"
      title="Scroll to top"
      aria-label="Scroll to top"
      onMouseDown={scaleDownButton}
      onMouseUp={scaleUpButton}
      data-magnetic-fixed
      className={cn(
        css.container,
        disabled && "pointer-events-none ease-in opacity-0"
      )}
    >
      <svg
        className={css.border}
        viewBox="0 0 100 100"
        fill="none"
        stroke="currentColor"
      >
        <circle cx="50" cy="50" r="45" strokeWidth="10" opacity={0.1} />
        <circle
          cx="50"
          cy="50"
          ref={circleRef}
          r={circleRadius}
          strokeDasharray={circleDashArray}
          strokeDashoffset={circleDashArray}
          strokeWidth="3"
        />
      </svg>
      <span className="arrow absolute top-0 left-0 w-full h-full flex items-center justify-center">
        <svg
          width="15"
          height="17"
          viewBox="0 0 15 17"
          fill="none"
          stroke="currentColor"
        >
          <line x1="8.00024" y1="1" x2="8.00024" y2="17" strokeWidth="2" />
          <path
            d="M8.00024 1.41415L2.05037 7.36397"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <line
            x1="1"
            y1="-1"
            x2="8.8995"
            y2="-1"
            transform="matrix(0.707107 0.707107 0.707107 -0.707107 8.00024 0)"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </span>
    </a>
  )
}
export default BackToTop
