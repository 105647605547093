import "./text-roll-over.css"

/**
 * Create a span element
 * to wrap the target content.
 */
const createSpanLabel = (html: string): HTMLSpanElement => {
  const container = document.createElement("span")
  container.setAttribute("data-rollover-label", "")
  container.setAttribute("data-text", html)

  const label = document.createElement("span")
  label.innerHTML = html

  container.appendChild(label)
  return container
}

/**
 * Check if a target
 * element has been already processed.
 */
const isElementNotTreated = (e: HTMLElement) =>
  e.querySelector("[data-rollover-label]") === null

/**
 * Create the effect.
 */
// TODO: Hide pseudo-elements when they supposed been out of view.
export default function addTextRollOverEffect() {
  // Select elements.
  const elements = document.querySelectorAll<HTMLElement>("[data-rollover]")
  const arrayOfElements = Array.from<HTMLElement>(elements)

  // Create a span for the label.
  arrayOfElements
    .filter((element) => isElementNotTreated(element))
    .forEach((element) => {
      const spanLabelContainer = document.createElement("em")
      spanLabelContainer.setAttribute("data-rollover-mask", "")
      spanLabelContainer.setAttribute("data-text", element.innerHTML)
      spanLabelContainer.appendChild(createSpanLabel(element.innerHTML))

      element.innerHTML = ""
      element.appendChild(spanLabelContainer)
    })


  const clean = () => true
  return clean
}
