import { gsap } from "gsap"

export const leave = () => {
  const element = document.querySelector("#main")
  return gsap
    .timeline({ paused: true })
    .to(element, { autoAlpha: 0, duration: 0.4, ease: "linear" })
}

export const enter = () => {
  const element = document.querySelector("#main")
  return gsap
    .timeline({ paused: true })
    .to(element, { autoAlpha: 1, duration: 0.4, ease: "linear" })
}
