/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useLayoutEffect, useRef } from "react"
import cn from "clsx"

import * as css from "./cursor.module.css"
import { Store, useStore } from "@/store/use-store"
import { useFrame } from "@/hooks/use-frame"

const Cursor = () => {
  const ease = 0.1
  const x = useRef<number>(0)
  const y = useRef<number>(0)
  const targetX = useRef<number>(0)
  const targetY = useRef<number>(0)
  let bounds: DOMRect | null = null
  const $cursor = useRef<HTMLDivElement>(null)
  const [cursorIsVisible] = useStore((state: Store) => [state.cursorIsVisible])

  useLayoutEffect(() => {
    // Get sizes.
    if ($cursor.current) {
      bounds = $cursor.current.getBoundingClientRect()
    }

    // Get target position.
    const onMouseMove = (event: MouseEvent) => {
      if (bounds) {
        const { width, height } = bounds
        targetX.current = event.clientX - width / 2
        targetY.current = event.clientY - height / 2
      }
    }
    document.body.addEventListener("mousemove", onMouseMove)

    const rAF = useFrame("write", () => {
      // Get current position.
      x.current += (targetX.current - x.current) * ease
      y.current += (targetY.current - y.current) * ease

      // Set the cursor position.
      if ($cursor.current) {
        $cursor.current.style.transform = `translate3d(${x.current}px,${y.current}px,0px)`
      }
    })

    return () => {
      rAF.dispose()
      document.body.removeEventListener("mousemove", onMouseMove)
    }
  }, [])

  return (
    <div
      ref={$cursor}
      className={cn(css.cursor, cursorIsVisible && "is-visible")}
      tabIndex={-1}
    >
      <svg
        x="0px"
        y="0px"
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        fill="none"
        stroke="currentColor"
      >
        <circle
          cx="50"
          cy="50"
          r="48"
          strokeWidth="4"
          opacity={0.2}
        />
        <circle className={css.circle} cx="50" cy="50" r="48" strokeWidth="2" />
      </svg>
    </div>
  )
}

export default Cursor
