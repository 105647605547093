import { calculate, split } from "@/lib/text"

import "./text-reveal.css"
import { useFrameOnce } from "@/hooks/use-frame"

// const splitTextIntoLines = (element: HTMLElement) => {
//   split({ element })
//   split({ element })
//   return calculate(element?.querySelectorAll<HTMLSpanElement>("span span"))
// }

type TextRevealEffectItem = {
  trigger: HTMLElement
  element: HTMLElement
  position: number
}

/**
 * [data-splittext]
 *
 * [data-splittext-root]
 * [data-splittext-item]
 */
export default function addTextRevealEffect() {
  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const element = entry.target
          element.classList.add("in-view")
          observer.unobserve(element)
        }
      })
    },
    { threshold: 0.3 }
  )

  // Select elements.
  const elementsWithRoot: TextRevealEffectItem[] = Array.from(
    document.querySelectorAll<HTMLElement>("[data-splittext-root]")
  )
    .map((root) =>
      Array.from<HTMLElement>(
        root.querySelectorAll("[data-splittext-item]")
      ).map((element, index) => ({
        trigger: root,
        element,
        position: index,
      }))
    )
    .flat()

  const elementsWithoutRoot: TextRevealEffectItem[] = Array.from(
    document.querySelectorAll<HTMLElement>("[data-splittext]")
  ).map((element) => ({
    trigger: element,
    element,
    position: 0,
  }))

  elementsWithoutRoot
    .concat(elementsWithRoot)
    .forEach(({ trigger, element, position }) => {
      // Split text into lines
      split({ element })
      split({ element })
      useFrameOnce("read", () => {
        calculate(element?.querySelectorAll("span span"))
        // trigger.element = element
        observer.observe(element)
      })
    })

  return () => {
    // console.log("removeRevealTextEffect")
  }
}
