import React, { useEffect, useRef } from "react"
import cn from "clsx"
import { gsap } from "gsap"
// import { CustomEase } from "gsap/CustomEase"
import { Link } from "gatsby"

import * as css from "./navigation.module.css"
import { SiteMenuItem } from "@/hooks/use-site-menu"
import useNavbarStore from "../store"

type NavbarNavigationProps = {
  links: SiteMenuItem[]
  className?: string
}

const NavbarNavigation: React.FC<NavbarNavigationProps> = ({
  links,
  className,
}) => {
  // Connect to the store.
  const navIsOpen = useNavbarStore((state) => state.opened)
  const $rootRef = useRef<HTMLElement>(null)

  // Set enter/leave animations.
  useEffect(() => {
    gsap.context(() => {
      if ($rootRef.current) {
        const stagger = navIsOpen ? 0.15 : 0
        const duration = navIsOpen ? 1.2 : 0.1
        const yPercent = navIsOpen ? 0 : 100
        const ease = navIsOpen ? "expo.out" : "power1.in"

        // Remove hidden from navigation first.
        gsap.set($rootRef.current, { autoAlpha: 1 })
        gsap.to(".nav-link", {
          yPercent,
          // skewY: navIsOpen ? 0 : 7,
          ease,
          duration,
          stagger,
          overwrite: true,
          onComplete: () => {
            // Set hidden to navigation if it has been closed.
            if (!navIsOpen) gsap.set($rootRef.current, { autoAlpha: 0 })
          },
        })
      }
    }, $rootRef)

    return () => {
      // ctx.revert() // cleanup!!
    }
  }, [navIsOpen, $rootRef])

  return (
    <nav
      ref={$rootRef}
      id="navbarCollapse"
      aria-label="Main"
      className={cn(css.container, className, "pt-8  xl:pt-0")}
    >
      <ul className={css.nav}>
        {links.map(({ label, path }, index) => (
          <li key={index} className={css.navItem}>
            <Link
              to={path}
              aria-label={label}
              title={label}
              className={cn(css.navLink, "nav-link")}
              data-rollover
            >
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default NavbarNavigation
