/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react"

import { useStore } from "@/store/use-store"

export const useScroll = (callback: (...args: any[]) => any, deps = []) => {
  const lenis = useStore(({ lenis }) => lenis)

  useEffect(() => {
    if (!lenis) return
    lenis.on("scroll", callback)

    return () => {
      lenis.off("scroll", callback)
    }
  }, [lenis, callback, [...deps]])
}
