import useEvents from "@/hooks/use-events"
import { gsap } from "gsap"

export type MagneticGripOptions = {
  x?: number
  y?: number
}

type MagnetizeOptions = {
  element: HTMLElement
  /**
   * The magnetic grip.
   * @type {number}
   */
  grip?: MagneticGripOptions
  /**
   * If the element
   * position is fixed or not.
   */
  fixed?: boolean
}

/**
 * Magnetize
 * @author Mystro Ken <mystroken@gmail.com>
 */
export default function initialize({
  element,
  grip,
  fixed = false,
}: MagnetizeOptions): () => void {
  // Element bound.
  let bound: DOMRect
  const events = useEvents()

  // Some constants.
  const speed = 0.4
  const gripX = grip?.x || 0.4
  const gripY = grip?.y || 0.4
  const offsetHoverMax = 0.7

  /**
   * Handle on mouseenter.
   */
  const mouseenter = () => gsap.killTweensOf(element)

  /**
   * On Leave, move to idle.
   */
  const mouseleave = () => {
    gsap.killTweensOf(element)
    gsap.to(element, {
      x: 0,
      y: 0,
      // rotation: 0,
      overwrite: true,
      ease: "power2.out", // "elastic.out(1.1, .4)",
      duration: 0.7,
    })
  }

  /**
   * Handle on mousemove.
   */
  const mousemove = (event: MouseEvent) => {
    // Track the mouse position.
    const cursor = {
      x: event.clientX,
      y: event.clientY,
    }

    // Track the scroll position.
    const windowScrollX = !fixed ? window.scrollX : 0
    const windowScrollY = !fixed ? window.scrollY : 0

    // Calculate the distance.
    const x = cursor.x + windowScrollX - bound.x
    const y = cursor.y + windowScrollY - bound.y
    const distance = Math.hypot(x, y)

    // Move the item inside the perimeter.
    if (distance < bound.width * offsetHoverMax) {
      gsap.to(element, {
        x: x * gripX,
        y: y * gripY,
        // rotation: x * 0.05,
        overwrite: true,
        ease: "power2.out",
        duration: speed,
      })
    }
  }

  /**
   * Handle on resize.
   */
  const resize = () => {
    bound = element.getBoundingClientRect()
    // The transform origin is the center.
    bound.x += bound.width / 2
    bound.y += bound.height / 2
  }

  /**
   * Set up the lib.
   */
  const setup = () => {
    resize()
    attachEvents()
  }

  /**
   * Add event listeners.
   */
  function attachEvents() {
    events.on("viewport:resize", resize)
    element.addEventListener("mouseenter", mouseenter)
    element.addEventListener("mouseleave", mouseleave)
    element.addEventListener("mousemove", mousemove)
  }

  /**
   * Remove event listeners.
   */
  function removeEvents() {
    events.off("viewport:resize", resize)
    element.removeEventListener("mouseenter", mouseenter)
    element.removeEventListener("mouseleave", mouseleave)
    element.removeEventListener("mousemove", mousemove)
  }

  /**
   * Destroy the instance.
   */
  const cleanup = () => removeEvents()

  setup()
  return cleanup
}
