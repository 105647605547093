import "./magnetic.css"
import magnetize, { MagneticGripOptions } from "./magnetize"

/**
 * magnetic effect
 * to apply on
 *
 * class-magnetic
 * data-strength-root
 * data-strength-inner
 *
 * @author Mystro Ken <mystroken@gmail.com>
 */
export default function useMagneticEffect() {
  // Select elements.
  const elements = document.querySelectorAll<HTMLElement>(
    "[data-magnetic], [data-magnetic-fixed]"
  )
  const arrayOfElement = Array.from(elements)

  // Filter candidate elements.
  const isTouchDevice =
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  if (isTouchDevice || arrayOfElement.length < 1) return () => true

  // Magnetize each element.
  const garbageCollector = arrayOfElement.map((element) => {
    // Get options from element.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const grip: MagneticGripOptions = JSON.parse(
      element.dataset.magnetic || "{}"
    )
    const fixed: boolean = element.dataset.magneticFixed
      ? element.dataset.magneticFixed === "true"
        ? true
        : false
      : false
    return magnetize({ element, grip, fixed })
  })

  // Free up memory.
  arrayOfElement.length = 0

  // Return a function that
  // cleanup everything.
  return () => {
    garbageCollector.forEach((cleanup) => cleanup())
    garbageCollector.length = 0
  }
}
