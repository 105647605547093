import { FC, useEffect } from "react"

import { useStore } from "@/store/use-store"
import { useFrame } from "@/hooks/use-frame"

type ScrollManagerProps = {
  trigger: unknown
}

/**
 * Set up Lenis for smooth scroll.
 */
const ScrollManager: FC<ScrollManagerProps> = ({ trigger }) => {
  const [lenis, setLenis] = useStore((state) => [state.lenis, state.setLenis])
  const initialize = async () => {
    const Lenis = await import("@studio-freight/lenis")
    return new Lenis.default({
      duration: 1,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      direction: "vertical",
      gestureDirection: "vertical",
      smooth: true,
      mouseMultiplier: 1,
      smoothTouch: false,
      touchMultiplier: 2,
      infinite: false,
    })
  }

  useEffect(() => {
    // Import Lenis.
    if (!lenis) {
      initialize()
        .then((lenis) => setLenis(lenis))
        .catch(() => null)
      return
    }

    // Lenis has been imported.
    // Exposing to the
    // global scope for ease of use.
    window.lenis = lenis

    // Use only one
    // requestAnimationFrame for your whole app.
    const rAF = useFrame("write", (time: number) => {
      lenis?.raf(time)
    })

    return () => {
      // clean rAF.
      rAF.dispose()

      // clean Lenis.
      lenis.destroy()
      setLenis(null)
      window.lenis = undefined
    }
  }, [lenis, trigger])

  // Ask Lenis to
  // manage internal navigation (hash)
  const scrollToHashTarget = (hash: string, shouldUpdateLocation = true) => {
    if (lenis && hash) {
      const target = document.querySelector(hash)
      lenis.stop()
      lenis.start()
      lenis.scrollTo(target, {
        offset: 0,
        duration: 1.2,
        // easeInOutExpo
        easing: (x) =>
          x === 0
            ? 0
            : x === 1
            ? 1
            : x < 0.5
            ? Math.pow(2, 20 * x - 10) / 2
            : (2 - Math.pow(2, -20 * x + 10)) / 2,
      })
      if (shouldUpdateLocation) window.location.hash = hash
    }
  }

  useEffect(() => {
    const { hash } = location

    // ScrollTrigger.refresh()

    // update scroll position
    // on page refresh based on hash
    if (hash != "") {
      scrollToHashTarget(hash)
    }

    // catch anchor links clicks.
    function handleClickOnInternalLink(this: HTMLAnchorElement, event: Event) {
      event.preventDefault()
      const currentHash = this.href.split("#").pop()
      const shouldUpdateLocation = this.dataset.silentlyScrollTo ? false : true
      if (currentHash)
        scrollToHashTarget("#" + currentHash, shouldUpdateLocation)
    }

    const internalLinks = Array.from<HTMLAnchorElement>(
      document.querySelectorAll("[href]")
    ).filter((node) => node.href.includes(location.pathname + "#"))

    internalLinks.forEach((node) => {
      node.addEventListener("click", handleClickOnInternalLink, false)
    })

    // cleanup.
    return () => {
      internalLinks.forEach((node) => {
        node.removeEventListener("click", handleClickOnInternalLink, false)
      })
    }
  }, [lenis, trigger])

  return null
}
export default ScrollManager
