/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import cn from "clsx"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"

import * as css from "./footer.module.css"
import BackToTop from "../back-to-top"
import { useSiteMenu } from "@/hooks/use-site-menu"

type FooterProps = {
  triggers: unknown[]
}

const Footer: React.FC<FooterProps> = ({ triggers }) => {
  // const location = useLocation()
  const { socialNetworkLinks } = useSiteMenu()

  React.useEffect(() => {
    const mm = gsap.matchMedia()
    const $rootRef = document.querySelector<HTMLElement>("#footer")
    const $rootInner = $rootRef?.querySelector<HTMLElement>("#footerInner")

    if ($rootInner) {
      mm.add("(min-width: 1024px)", () => {
        const uncover = gsap.timeline({ paused: true })
        uncover.fromTo(
          $rootInner,
          { yPercent: -35 },
          { yPercent: 0, ease: "none" }
        )

        ScrollTrigger.create({
          // markers: true,
          id: "footer-parallax",
          animation: uncover,
          trigger: $rootRef,
          invalidateOnRefresh: true,
          scrub: true,
          start: "top bottom",
          // end: "bottom bottom",
          end: () => `+=${Math.min(window.innerHeight, parseInt($rootRef?.offsetHeight.toString() || ""))}`,
          onEnter: () => ($rootInner.style.willChange = "transform"),
          onLeave: () => ($rootInner.style.willChange = "auto"),
        })

        return () => {
          uncover.revert()
          $rootInner.style.willChange = ""
        }
      })
    }

    return () => {
      mm.kill()
    }
  }, triggers)

  return (
    <footer id="footer" className={css.footer}>
      <div id="footerInner" className={css.footerInner}>
        <div className={cn(css.footerContent, "row")}>
          {/* card-image  */}
          <StaticImage
            src="../../images/illustrations/coloured-puzzle.png"
            alt="A picture showing a colourful puzzle"
            width={720}
            height={720}
            onLoad={() => ScrollTrigger.refresh()}
            className={cn(
              "col-span-full w-52 mx-auto flex justify-center items-center mb-10",
              "md:mb-0 md:w-full md:row-start-1 md:col-span-5 md:col-start-7"
            )}
          />
          {/* card-body  */}
          <div
            className={cn(
              "col-span-full text-center",
              "md:row-start-1 md:col-span-7 md:col-start-1 md:text-left",
              "xl:col-span-6 xl:col-start-2"
            )}
          >
            <h2 className="heading-1 mb-[2.22rem] max-w-lg mx-auto md:mx-0">
              Let’s build a user-centric software, together
            </h2>
            <p className="mb-[1.33rem] max-w-xs mx-auto md:mx-0 text-gray-400">
              From small-scale websites to large-scale applications, I would
              love to help you achieve your goals.
            </p>
            <h3
              className={cn(
                css.footerMail,
                "space-x-1 text-xl py-[.89rem] flex flex-col-reverse justify-center items-center md:flex-row md:justify-start"
              )}
            >
              <a
                className={cn(
                  css.footerMailLink,
                  "anchor-underlined font-medium"
                )}
                href="mailto:hello@mystroken.com"
                title="Drop an email to this email"
              >
                hello[at]mystroken.com
              </a>
              {/* <span>&nbsp;</span> */}
              <span className={css.footerHandIcon}>👋🏾</span>
            </h3>
          </div>
        </div>

        <div className={cn(css.footerMeta, "row")}>
          <div
            className={cn(
              "col-span-full space-y-1 text-center",
              "md:space-y-0 md:flex md:justify-between md:items-center",
              "xl:col-span-10 xl:col-start-2"
            )}
          >
            <nav aria-label="Social">
              <ul
                className={cn(
                  "flex flex-row justify-center items-center space-x-2",
                  "md:order-2"
                )}
              >
                {socialNetworkLinks.map(({ label, path }, index) => (
                  <li key={index}>
                    <a
                      href={path}
                      aria-label={label}
                      title={label}
                      target="_blank"
                      rel="noreferrer nofollow"
                      data-rollover
                    >
                      {label}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
            <p className="md:order-3">
              Special thanks to{" "}
              <a
                title="Jaslin Tonton's website"
                href="https://hellotonton.com"
                data-rollover
              >
                Jaslin Tonton
              </a>
            </p>
            <h3 className="md:-order-1">
              <span>Copyright — ©{new Date().getFullYear()} Mystro Ken</span>
            </h3>
          </div>
        </div>
      </div>
      {/* <div className={css.footerBackgroundLight} /> */}
      <BackToTop />
    </footer>
  )
}

export default Footer
