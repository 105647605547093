import type { gsap } from "gsap"
import { enter as FadeEnter, leave as FadeLeave } from "./fade"
import { enter as SlideEnter, leave as SlideLeave } from "./slide"

export type TransitionItemType = {
  enter: () => gsap.core.Timeline
  leave: () => gsap.core.Timeline
}

export type TransitionListType = {
  fade: TransitionItemType
  slide: TransitionItemType
}

const TransitionList: TransitionListType = {
  fade: {
    enter: FadeEnter,
    leave: FadeLeave,
  },
  slide: {
    enter: SlideEnter,
    leave: SlideLeave,
  }
}

export default TransitionList
